span,
label,
input,
a,
p {
  font-size: 0.88rem !important;
  letter-spacing: 0px !important;
  font-family: Nunito, sans-serif !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

a {
  font-weight: 600 !important;
  color: #206cff !important;
}

p,
span,
p span {
  color: #3e4e5d;
}

.infobox-error p {
  color: #d40033;
}

/* SignIn button  */

#okta-sign-in #okta-signin-submit {
  display: none;
}

/* End SignIn button */

/* Primary buttons block */

#okta-sign-in .o-form-button-bar .button {
  margin-bottom: 10px;
}

/* End Primary buttons block */

#okta-sign-in .auth-divider {
  margin-top: 25px;
}

#okta-sign-in .social-auth-google-button.link-button {
  padding-top: 15px;
  margin-bottom: 20px;
  color: #5e5e5e !important;
}

#okta-sign-in .o-form-button-bar {
  padding: 0;
}

#okta-sign-in .o-form-button-bar.focused-input {
  margin-bottom: 10px;
  height: 46px;
}

#okta-sign-in .o-form-content {
  padding: 0;
}

/* Email block */

#okta-sign-in .email-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 20px;
}

#okta-sign-in .email-block .change-login-link {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  color: #206cff;
}

#okta-sign-in .email-block .email-text {
  font-family: 'Nunito', sans-serif;
  color: #3e4e5d;
}

/* End email block*/

/* Error block*/

.error-auth-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px !important;
}

.error-text {
  font-family: 'Nunito', sans-serif;
  color: #d40033;
}

/* End error block */

/* Inout password value additional field  */

#okta-sign-in .password-input-visible {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: white !important;
  padding: 6px;
  border-radius: 4px;
}

/* End inout password value additional field */

/* Registration and help */

.help-links {
  display: flex !important;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.help-links li {
  margin-top: 0px !important;
}

#okta-sign-in .registration-container .registration-label {
  margin-right: 5px;
  color: #77838d;
  font-weight: 600 !important;
}

.gfh-login-link {
  color: #206cff !important;
  font-size: 1rem !important;
  padding-bottom: 15px !important;
}

#okta-sign-in .registration-container .content-container {
  border-top: none;
  padding: 0;
}

.registration-container .content-container {
  display: flex;
  flex-direction: row;
}

.registration-container {
  margin-top: 20px !important;
}

/* End Registration and help */

/* Remember me checkbox */

#okta-sign-in .o-form-input-name-remember label {
  padding: 7px 10px 5px 24px;
}

#okta-sign-in .o-form-input-name-remember .custom-checkbox {
  border: 1px solid transparent;
}

#okta-sign-in .o-form-input-name-remember .custom-checkbox.focused-input {
  border: 1px dotted #d4d8e1 !important;
  box-shadow: initial;
}

/* End remember me checkbox*/

#okta-sign-in.auth-container .okta-sign-in-header {
  border: 0px;
}

#okta-sign-in.auth-container h2 {
  color: #3e4e5d;
  font-size: 1.575rem;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 10px;
}

#okta-sign-in.auth-container .okta-form-subtitle {
  text-align: center;
  margin-bottom: 5px;
  color: #77838d;
}

#okta-sign-in.auth-container .okta-form-subtitle.o-form-explain {
  margin-bottom: 20px;
}

#okta-sign-in.auth-container.main-container {
  border: none;
  min-height: 650px;
  margin-top: 0px;
  box-shadow: none;
  color: #77838d;
}

#okta-sign-in .flex {
  display: flex !important;
  flex-direction: column;
}

#okta-sign-in.auth-container .okta-form-label {
  color: #3e4e5d;
}

/* Password input */

#okta-sign-in #okta-signin-password {
  position: relative;
  height: 100%;
}

/* End password input */

/* Password eye icon */

#okta-sign-in .eye-button-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  display: inline-block;
  right: 12px;
  top: 12px;
}

#okta-sign-in .eye-button-icon.hide {
  background-image: url('../assets/view-off.svg');
}

#okta-sign-in .eye-button-icon.show {
  background-image: url('../assets/view.svg');
}

/* End password eye icon */

/* Error Tip Block */

#okta-sign-in .error-tip-block {
  margin-top: 10px;
}

#okta-sign-in .error-tip-text {
  font-size: 0.75rem !important;
  color: #77838d;
}

/* End Error tip block*/

/* Auth footer  */

#okta-sign-in .auth-footer {
  display: flex;
  justify-content: flex-end;
}

/* End Auth footer */

/* Login Logo*/
#okta-sign-in .auth-org-logo {
  max-width: 90px;
  width: 100%;
  height: 60px;
  max-height: 60px;
}

/* End Login Logo*/

/* Form fieldset container  */

#okta-sign-in .o-form-fieldset-container {
  margin-bottom: 15px;
}

/* End Form fieldset container  */

/* Form fieldset */

#okta-sign-in .o-form-fieldset {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#okta-sign-in .o-form .o-form-fieldset:last-child {
  margin-bottom: 15px !important;
}

/* End Form fieldset */

/*Errors*/

#okta-sign-in .infobox {
  border: none;
  padding-left: 0px !important;
  padding-top: 0px !important;
}

#okta-sign-in .o-form-has-errors .infobox-error {
  padding: 0 !important;
  padding-bottom: 15px !important;
  text-align: center;
}

#okta-sign-in .o-form-has-errors .infobox-error {
  display: flex !important;
  justify-content: center;
  font-size: 0.9rem !important;
  font-family: Nunito, sans-serif;
}

.infobox-error {
  color: #d40033;
}

.infobox-error::before {
  content: none !important;
}

#okta-sign-in .infobox:before {
  content: none !important;
}

#okta-sign-in .infobox > .icon::before {
  content: none;
}

#okta-sign-in
  .o-form:has(.guesty-invalid-password)
  .o-form-input-name-newPassword {
  border: 1px solid #d40033 !important;
  background-color: #fff8f9;
}

/*End Errors*/

#okta-sign-in.auth-container .okta-form-input-field {
  border: solid 1px #d4d8e1;
  border-radius: 4px;
  height: 46px;
}

#okta-sign-in.auth-container .okta-form-input-field .o-form-label-inline {
  border: 1px solid #d4d8e1;
  border-radius: 2px;
}

/* Phone input */
#okta-sign-in.auth-container
  .o-form-input-name-phoneNumber.okta-form-input-field,
#okta-sign-in.auth-container
  .o-form-input-name-phoneNumber.okta-form-input-field:active,
#okta-sign-in.auth-container
  .o-form-input-name-phoneNumber.okta-form-input-field,
#okta-sign-in.auth-container
  .o-form-input-name-phoneNumber.okta-form-input-field:hover {
  border: none !important;
}

/* End phone input */

#okta-sign-in.auth-container .okta-form-input-field:hover {
  border: solid 1px #206cff !important;
  transition: all 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

#okta-sign-in.auth-container .o-form-label {
  padding-bottom: 10px;
}

.focused-input {
  border: solid 1px #206cff !important;
}

.error-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px !important;
}

.error-text {
  color: #d40033;
}

/* Enroll factor block */

#okta-sign-in .enroll-factor-row .enroll-factor-label {
  font-size: 0.9rem;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
  line-height: 18px;
  color: #3e4e5d;
  margin-bottom: 5px;
}

#okta-sign-in .enroll-factor-row .enroll-factor-button {
  max-width: 210px;
}

#okta-sign-in .enroll-factor-row .enroll-factor-button .button {
  width: 100%;
  height: 36px;
  line-height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #206cff;
  border-radius: 2px;
  background-color: initial;
  background: initial;
  color: #206cff !important;
  font-weight: 700 !important;
  font-size: 0.88rem !important;
}

#okta-sign-in .enroll-factor-row .enroll-factor-button .link-button:focus,
#okta-sign-in .enroll-factor-row .enroll-factor-button .link-button:hover {
  border-color: #0074b3;
}

/* End Enroll factor block */

/* Button */
#okta-sign-in.auth-container .button {
  height: 46px;
  font-weight: 700 !important;
  font-size: 1rem !important;
  box-shadow: 0px 2px 4px rgba(33, 33, 33, 0.1);
  border: 1px solid transparent;
  border-radius: 8px;
  margin-top: 0;
  color: white !important;
  line-height: 46px !important;
}

/* Continue button */
#okta-sign-in.auth-container .btn-continue:active,
#okta-sign-in.auth-container .btn-continue:focus {
  box-shadow: 0 0 8px #51cbee;
  border-color: #0074b3;
  background-color: #2271ff;
  background-image: none;
  border: 1px solid #206cff !important;
}

.sms-request-button {
  margin-top: 34px !important;
}

/* Beacon border */
#okta-sign-in .auth-beacon-border {
  border: none;
}

/* End beacon border*/

.okta-form-infobox-warning.login-timeout-warning {
  color: #77838d;
}

/* Phone input styles */

.chzn-container.chzn-container-single.chzn-container-active a,
#okta-sign-in .chzn-container-single .chzn-single {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  justify-content: space-between;
}

#okta-sign-in .chzn-container-single .chzn-single {
  border: 1px solid #d4d8e1;
  border-radius: 2px;
}

.chzn-container.chzn-container-single.chzn-container-active a div,
#okta-sign-in .chzn-container-single .chzn-single div {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  border: none;
  border-left: 1px solid #d4d8e1;
  text-align: center;
  padding: 0px;
  height: 46px;
}

.chzn-container.chzn-container-single.chzn-container-active a div {
  height: 36px;
}

#okta-sign-in .chzn-container-single .chzn-single div b {
  background-position: 10px 12px;
}

.chzn-container.chzn-container-single.chzn-container-active a div b {
  background-position: -8px 10px;
}

#okta-sign-in.auth-container .chzn-single span {
  color: #212121;
}

/* End phone input styles */

/* Form divider */
#okta-sign-in .form-divider {
  margin-bottom: 10px;
}

/* End form divider */

/* Sms button */

#okta-sign-in .enroll-sms .sms-request-button,
#okta-sign-in .mfa-verify .sms-request-button {
  padding: 0;
  background-color: #ffffff !important;
  background: initial;
  border: 1px solid #206cff;
  border-radius: 8px;
  color: #206cff !important;
  font-weight: 400 !important;
  box-shadow: initial;
  margin-top: 35px !important;
}

#okta-sign-in .enroll-sms .sms-request-button {
  width: 153px;
}

#okta-sign-in .mfa-verify .sms-request-button {
  width: 140px;
}

/* End SMS button */

#okta-sign-in .enroll-sms .enroll-sms-phone {
  width: 153px;
}

#okta-sign-in .beacon-container {
  width: 99px;
  height: 99px;
}

/* Email block */

#okta-sign-in .mask-email {
  color: #77838d;
}

#okta-sign-in .enroll-activate-email .resend-email-infobox,
#okta-sign-in .enroll-email .o-form-content.o-form-theme {
  margin-bottom: 0;
}

#okta-sign-in .resend-email-infobox span {
  color: #77838d;
}

/* End email block */

.mb-20 {
  margin-bottom: 20px !important;
}

#okta-sign-in.auth-container .password-reset .o-form-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: 0;
  color: #3e4e5d;
}

#okta-sign-in.auth-container .password-reset .o-form-content {
  display: flex;
  flex-direction: column;
}

#okta-sign-in.auth-container .password-reset .o-form-head {
  order: 1;
}

#okta-sign-in.auth-container .password-reset .o-form-fieldset-container {
  order: 2;
}

#okta-sign-in.auth-container .password-reset .o-form-explain {
  order: 3;
}

#okta-sign-in.auth-container .password-reset .o-form-error-container {
  order: 4;
}

.forgot-password .okta-form-title {
  opacity: 0;
}

.lite-welcome-flow-wrapper {
  background: #f0eee8;
  grid-template-columns: 37fr 63fr;
}

.lite-welcome-flow-background {
  width: 100%;
  height: 100%;
  background-image: url('../assets/bg-welcome-flow-v3.webp');
  background-position: center;
  background-size: cover;
  order: -1;
  display: flex;
  align-items: flex-start !important;
  padding: 170px 47px 0 47px;
}

.lite-welcome-flow-background-text {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.lite-okta-sign-in#okta-sign-in.auth-container.main-container {
  padding: 40px;
  width: 100%;
  max-width: 440px;
  height: auto;
  min-height: unset;
  border-radius: 16px;
}

.lite-okta-sign-in#okta-sign-in .okta-sign-in-header {
  display: none;
}

.lite-okta-sign-in#okta-sign-in.auth-container h2 {
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 40px;
  margin-top: 0;
}

.lite-okta-sign-in#okta-sign-in .password-validations-container ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0;
  margin-top: 12px;
  margin-bottom: 0;
}

.lite-okta-sign-in#okta-sign-in .password-validations-container ul li {
  font-size: 12px !important;
  line-height: 18px;
}

.lite-welcome-flow-wrapper .steps-wrapper {
  width: 100%;
  max-width: 320px;
  height: 4px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.lite-welcome-flow-wrapper {
  .step {
    background-color: #cccccc;
  }

  .step-active {
    background-color: #2053f8;
  }

  .step-finished {
    background-color: #14a44b;
  }
}

.lite-welcome-flow-wrapper-side {
  flex-direction: column;
  padding: 40px;
  justify-content: space-between !important;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .lite-welcome-flow-background {
    display: none !important;
  }

  .lite-welcome-flow-wrapper {
    display: flex;
    justify-content: center;
  }

  .lite-okta-sign-in#okta-sign-in .password-validations-container ul {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .lite-okta-sign-in#okta-sign-in.auth-container.main-container {
    min-width: min(100%, 440px);
    width: auto;
    padding: 32px 20px 16px 20px;
    margin: 0;
  }

  .lite-welcome-flow-wrapper-side > div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }

  .lite-okta-sign-in#okta-sign-in.auth-container.main-container .auth-content {
    padding: 0;
    max-width: 100%;
  }

  .lite-welcome-flow-wrapper .steps-wrapper {
    height: 0;
  }
}
